import React, { useState } from 'react';
import { useForm } from '@formspree/react';
import { TextField, Typography } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';
import { Button, ButtonColor, ButtonSize, ButtonVariant } from '../../Button';
import './ComplaintForm.css';
import { ButtonPaddingSize } from '../../Button/Button.types';

const ComplaintForm = () => {
  const [state, handleSubmit] = useForm('xqkryvln');
  const [phone, setPhone] = useState<string>('');

  if (state.succeeded) {
    return (
      <div>
        <Typography
          variant="h6"
          sx={{
            marginTop: '2%',
            marginBottom: '7%',
            alignContent: 'center',
            color: '#153240',
            fontSize: {
              sm: '1rem',
              xs: '0.75rem'
            }
          }}>
          ¡Muchas gracias! Recibimos tu reclamo, lo vamos a revisar y nos contactamos pronto.
        </Typography>
      </div>
    );
  }

  return (
    <form
      className="complaintForm"
      id="complaintForm"
      name="complaint-form"
      acceptCharset="utf-8"
      onSubmit={handleSubmit}>
      <TextField
        id="name"
        key="name"
        placeholder="Nombre"
        variant="outlined"
        margin="dense"
        type="text"
        name="name"
        required
      />
      <TextField
        id="email-address"
        key="email"
        placeholder="Email"
        variant="outlined"
        margin="dense"
        type="email"
        name="_replyto"
        required
      />
      <MuiTelInput
        id="telephone"
        key="phone"
        placeholder="Teléfono"
        variant="outlined"
        margin="dense"
        required
        defaultCountry="AR"
        name="telephone"
        value={phone}
        onChange={setPhone}
      />
      <TextField
        name="complaint"
        multiline
        rows={6}
        id="complaint"
        key="complaint"
        placeholder="Escribí aca tu reclamo ..."
        variant="outlined"
        margin="dense"
        type="text"
        required
      />
      <input type="hidden" name="_subject" id="email-subject" value="Reclamo Estudi" />
      <div className="complaintFormButton">
        <Button
          variant={ButtonVariant.CONTAINED}
          type="submit"
          isLoading={state.submitting}
          color={ButtonColor.PRIMARY}
          size={ButtonSize.MD}
          paddingSize={ButtonPaddingSize.MD}>
          Enviar
        </Button>
      </div>
    </form>
  );
};

export default ComplaintForm;
