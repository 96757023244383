import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import { trackClickEvent } from '../../../utils/functions/trackEvent';
import { Course } from '../../../types/Course';
import ProfilePicture from '../../ProfilePicture/ProfilePicture';
import { TeacherInfo } from '../../../types/TeacherInfo';
import './RecentCourseCard.css';
import { ButtonColor, ButtonLink, ButtonVariant } from '../../Button';
import { ButtonPaddingSize } from '../../Button/Button.types';
import Rate from '../../Rate';

const RecentCourseCard = ({ course, teacher }: { course: Course; teacher: TeacherInfo }) => {
  const handleTeacherClick = () => {
    trackClickEvent('recent_teacher_course_card', teacher.external_id);
  };

  const handleCourseClick = () => {
    trackClickEvent('recent_course_card', course.label);
  };

  return (
    <Card className="recentCourseCard">
      <Link
        to={`/teachers?courseId=${course.value}`}
        className="cardContent"
        onClick={() => handleCourseClick()}>
        <div className="cardHeader">
          <div>
            <h3 className="courseName">{course.label}</h3>
            <p className="institutionName">{course.institution.label}</p>
          </div>
        </div>
        <p className="teacherLabel">Último profesor:</p>
        <div className="teacherInfo">
          <ProfilePicture image_url={teacher.image_url} />
          <div>
            <div className="teacherName">
              {teacher.first_name} {teacher.last_name}
            </div>
            {teacher.rate && teacher.rate?.average && teacher.rate?.quantity && (
              <Rate rate={teacher.rate} />
            )}
          </div>
        </div>
      </Link>
      <div className="cardActions">
        <ButtonLink
          variant={ButtonVariant.TEXT}
          color={ButtonColor.PRIMARY}
          paddingSize={ButtonPaddingSize.SM}
          href={`/teachers?courseId=${course.value}`}
          onClick={() => handleCourseClick()}>
          Buscar por materia
        </ButtonLink>
        <ButtonLink
          variant={ButtonVariant.OUTLINED}
          color={ButtonColor.SECONDARY}
          paddingSize={ButtonPaddingSize.SM}
          href={`/teachers/${teacher.external_id}/profile?courseId=${course.value}`}
          onClick={() => handleTeacherClick()}>
          Reservar de nuevo
        </ButtonLink>
      </div>
    </Card>
  );
};

export default RecentCourseCard;
