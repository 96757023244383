import { Outlet } from 'react-router-dom';
import './Outline.css';
import Navbar from '../Navbar';
import Footer from '../Footer';

const Outline = () => {
  return (
    <div className="outline">
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Outline;
