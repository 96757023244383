import { EstudiApiClient } from '../../services/interfaces/EstudiApiClient/EstudiApiClient';
import { redirect } from 'react-router-dom';

export const loader =
  (estudiClient: EstudiApiClient) =>
  async ({ request }: { request: any }) => {
    const url = new URL(request.url);
    const courseId = url.searchParams.get('courseId');

    if (!courseId) {
      return redirect('/');
    }

    const course = await estudiClient.getCourseById({
      courseId: courseId
    });

    if (!course) {
      return redirect('/');
    }

    return {
      course,
      teachers: await estudiClient.getTeachers({
        institutionId: course.institution?.label!,
        courseId: courseId
      }),
      institution: course.institution
    };
  };
