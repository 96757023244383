import React from 'react';
import './AuthenticadRoute.css';
import { Navigate } from 'react-router-dom';
import { useAuthUserContext } from '../../../providers/AuthUserProvider';
import { ClipLoader } from 'react-spinners';

const AuthenticatedRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuthUserContext();

  if (isLoading) {
    return (
      <div className="authSpinner">
        <span>Aguarde un instante</span>
        <ClipLoader color="#184b76" />
      </div>
    );
  }

  return isAuthenticated ? children : <Navigate to="/auth/login" />;
};

export default AuthenticatedRoute;
