import { Course } from '../../../types/Course';

const fetchCourses = async (institutionId?: string): Promise<Course[]> => {
  const coursesUrl = `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/web/courses?${
    institutionId ? `institutionId=${institutionId}` : ''
  }`;

  try {
    const response = await fetch(coursesUrl);

    return await response.json();
  } catch (error) {
    console.error(error);

    return Promise.reject();
  }
};

export const coursesQueryFunction = (institutionId?: string) => ({
  queryKey: [`courses-${institutionId}`],
  queryFn: async (): Promise<Course[]> => fetchCourses(institutionId)
});
