import { Course } from '../../../types/Course';

export function coursesByInstitutionAdapter(courses: Course[]): Map<string, Course[]> {
  return courses.reduce((map, course) => {
    const institutionId = course.institution.value;
    const coursesByInstitution = map.get(institutionId) || [];

    coursesByInstitution.push(course);

    return map.set(
      institutionId,
      coursesByInstitution.sort((a, b) => a.label.localeCompare(b.label))
    );
  }, new Map<string, Course[]>());
}
