import Box from '@mui/material/Box';
import landingImage from '../../resources/images/banner.png';
import './Landing.css';
import ByNumbers from './ByNumbers';
import Tutorial from './Tutorial';
import { useNavigate } from 'react-router-dom';
import { trackClickButtonEvent } from '../../utils/functions/trackEvent';
import Footer from '../Footer';
import { Fragment } from 'react';
import { Button, ButtonColor, ButtonSize, ButtonVariant } from '../Button';
import { ButtonPaddingSize } from '../Button/Button.types';

const Landing = () => {
  const navigate = useNavigate();
  const handleCallToAction = (buttonName: string) => {
    trackClickButtonEvent(buttonName);
    navigate('/');
  };

  return (
    <Fragment>
      <div className="landing">
        <Box
          sx={{
            background: 'linear-gradient(#51a2ca,#184b76)',
            justifyContent: 'center',
            display: 'flex'
          }}>
          <div className="landing-container">
            <div className="landing-content">
              <div className="landing-title">
                <h1>estudi</h1>
                <h2>#SeAprueba</h2>
              </div>
              <span>Clases particulares por y para estudiantes universitarios</span>
              <div className="landing-mobile-image">
                <img src={landingImage} width={'300px'} />
              </div>
              <Button
                variant={ButtonVariant.CONTAINED}
                color={ButtonColor.SECONDARY}
                size={ButtonSize.MD}
                paddingSize={ButtonPaddingSize.MD}
                className="top-cta-button"
                onClick={() => handleCallToAction('landing_top_cta')}>
                Buscar mi profe ideal
              </Button>
            </div>
            <div className="landing-image">
              <img src={landingImage} width={'400px'} />
            </div>
          </div>
        </Box>
        <div className="about">
          <h1>¿Quiénes somos?</h1>
          <span>
            Desde el año 2018 en Estudi conectamos estudiantes universitarios con profes
            particulares para que esas materias o temas difíciles se te hagan más fáciles.
            <br /> Y lo mejor es que tu profe al ser un estudiantes avanzado ya paso por lo mismo
            que vos y sabe como ayudarte.
          </span>
        </div>
        <ByNumbers />
        <Tutorial />
        <div className="call-to-action">
          <Button
            variant={ButtonVariant.CONTAINED}
            color={ButtonColor.PRIMARY}
            size={ButtonSize.MD}
            paddingSize={ButtonPaddingSize.MD}
            className="bottom-cta-button"
            onClick={() => handleCallToAction('landing_bottom_cta')}>
            ¡ENCONTRÁ YA TU PROFE IDEAL!
          </Button>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Landing;
