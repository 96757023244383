import { Breadcrumbs, Link, Typography } from '@mui/material';
import './HelpPage.css';

export interface PageType {
  readonly title: string;
  readonly content: any;
}

const HelpPage = ({ page }: { page: PageType }) => {
  return (
    <div className="helpPage">
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        sx={{ marginLeft: '3%', fontSize: '0.8rem', marginBottom: '1%' }}>
        <Link underline="hover" color="primary" href="/help">
          Ayuda
        </Link>
        <Typography sx={{ color: 'primary', fontSize: '0.8rem' }}>{page.title}</Typography>
      </Breadcrumbs>
      <div className="pageTitle">{page.title}</div>
      {page.content}
    </div>
  );
};

export default HelpPage;
