import { Typography } from '@mui/material';
import './Privacy.css';

const Privacy = () => {
  return (
    <div className="privacy">
      <Typography variant="h5" component="h2">
        Políticas de privacidad
      </Typography>
      <Typography>
        Los datos personales serán utilizados para mantener el contacto y mantenerte al día de las
        actualizaciones, para enviarte la respuesta a la cuestión que solicitas al rellenar el
        formulario de contacto, realizar encuestas de satisfacción y opinión sobre el Sitio y los
        temas tratados en ella, o para informarte de las ofertas. Puede usted ejercer los derechos
        de acceso, rectificación o supresión de los datos personales facilitados voluntariamente por
        usted a través de algún formulario web, dirigiéndose a
        <a href="mailto:info@estudi.com.ar">info@estudi.com.ar</a>. Puede dejar de recibir estos
        emails en cualquier momento haciendo click en el link del pie de nuestros emails. Usamos
        SendGrid como nuestra plataforma de marketing. Al clickear debajo a suscribir, acepta que
        esta información sea transferida a SendGrid para su procesamiento. Aprenda más sobre las
        políticas de privacidad de SendGrid{' '}
        <a href="https://sendgrid.com/policies/privacy/">aquí</a>.
      </Typography>
      <Typography variant="h6" component="h2">
        Seguimiento de actividad
      </Typography>
      <Typography>
        El Usuario del Sitio conoce y acepta que Estudi podrá utilizar un sistema de seguimiento
        mediante la utilización de cookies. Las Cookies son pequeños archivos que se instalan en el
        disco rígido, con una duración limitada en el tiempo que ayudan a personalizar los servicios
        y a activar ciertas funcionalidades que sólo podrán estar disponibles mediante el empleo de
        Cookies. Se utilizará información obtenida mediantes las Cookies para analizar las páginas
        navegadas por el Usuario, las búsquedas realizadas, mejorar las iniciativas comerciales y
        promocionales, mostrar publicidad o promociones, banners de interés, perfeccionar la oferta
        de contenidos y artículos; también para promover y hacer cumplir las reglas y seguridad del
        sitio.
      </Typography>
      <Typography variant="h6" component="h2">
        Avisos publicitarios
      </Typography>
      <Typography>
        Cuando el Usuario hace “click” en avisos publicitarios o links de terceros e ingresa en
        otros sitios que no pertenecen a Estudi estará sujeto a los Términos y Condiciones de dichos
        sitios. El Usuario deberá leer detenidamente sus políticas de acceso y uso. Estudi no
        garantiza la legalidad, actualidad, calidad ni utilidad de los contenidos, operaciones e
        informaciones que se comuniquen, reproduzcan y/o realicen en sitios enlazados de terceros ni
        la ausencia de nocividad de tales contenidos o servicios, por lo que el Usuario exime de
        toda responsabilidad a Estudi por los contenidos incluidos en los referidos sitios o los
        servicios que en ellos se brindan o promocionan.
      </Typography>
    </div>
  );
};

export default Privacy;
