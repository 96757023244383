import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import './Help.css';

const Help = () => {
  return (
    <div className="help">
      <div className="helpTitle">¿En qué te podemos ayudar?</div>
      <div className="helpContainer">
        <div className="helpButtons">
          <Link to="/about" target="_blank" rel="noopener noreferrer">
            <div className="helpItem">
              <span>Sobre Nosotros</span>
              <ChevronRightIcon />
            </div>
          </Link>
          <Link to="/help/faq">
            <div className="helpItem">
              <span>Preguntas Frecuentes</span>
              <ChevronRightIcon />
            </div>
          </Link>
          <Link to="/help/request">
            <div className="helpItem">
              <span>Solicitud de profe</span>
              <ChevronRightIcon />
            </div>
          </Link>
          <Link to="/help/claims">
            <div className="helpItem">
              <span>Reclamos</span>
              <ChevronRightIcon />
            </div>
          </Link>
        </div>
        <div className="helpButtons">
          <Link to="/schools" target="_blank" rel="noopener noreferrer">
            <div className="helpItem">
              <span>Estudi Colegios</span>
              <ChevronRightIcon />
            </div>
          </Link>
        </div>
        <div className="helpButtons">
          <Link to="https://wa.me/1161297377" target="_blank" rel="noopener noreferrer">
            <div className="helpItem">
              <span>Contactanos</span>
              <ChevronRightIcon />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Help;
