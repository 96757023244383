import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA4 from 'react-ga4';
import { createBrowserRouter, RouterProvider, useLocation, Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Typography } from '@mui/material';
import { EstudiApiQueryClient } from './services/adapters/EstudiApiQueryClient';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { EstudiAuthApiClient } from './services/adapters/EstudiAuthApiClient';
import TeacherSearchProvider from './providers/TeacherSearchProvider';
import ReservationProvider from './providers/ReservationProvider';
import StudentUserProvider from './providers/StudentUserProvider';
import AuthenticatedRoute from './components/Auth/AuthenticatedRoute/AuthenticatedRoute';
import AuthUserProvider from './providers/AuthUserProvider';
import Outline from './components/Outline';
import ErrorPage from './components/Errors';
import { loader as homeLoader } from './components/Home/Home.loader';
import Home from './components/Home';
import { loader as resultsLoader } from './components/Results/Results.loader';
import Results from './components/Results';
import { loader as teacherProfileLoader } from './components/TeacherProfile/TeacherProfile.loader';
import TeacherProfile from './components/TeacherProfile';
import { loader as editTeacherProfileLoader } from './components/TeacherProfile/EditTeacherProfile/EditTeacherProfile.loader';
import EditTeacherProfile from './components/TeacherProfile/EditTeacherProfile/EditTeacherProfile';
import PaymentOutcome from './components/PaymentOutcome';
import Help from './components/Help';
import HelpPage from './components/Help/HelpPage';
import TeacherRequest from './components/Help/TeacherRequest';
import FAQ from './components/Help/FAQ/FAQ';
import ComplaintForm from './components/Help/ComplaintForm';
import Landing from './components/Landing';
import Schools from './components/Schools';
import Login from './components/Auth/Login';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import UserInteractionHistoryProvider from './providers/UserInteractionHistoryProvider';
import './Variables.css';
import './index.css';

//FixMe: Privacy and GDPR Compliance:
// Ensure that you follow privacy regulations like GDPR when collecting user data.
// Provide users with the option to opt out of tracking if required.
ReactGA4.initialize(process.env.REACT_APP_GA_TRACKING_ID!);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient();
const estudiClient = new EstudiApiQueryClient(queryClient, new EstudiAuthApiClient());

const PageViewTracker = ({ outline }: { outline: boolean }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA4.send({ hitType: 'pageview', page: location.pathname + location.search });
  }, [location]);

  return outline ? <Outline /> : <Outlet />;
};

const HelpPages = {
  claims: {
    content: (
      <div>
        <Typography
          variant="h6"
          component="div"
          align="left"
          sx={{
            marginLeft: '3%',
            fontSize: {
              sm: '1rem',
              xs: '0.75rem'
            }
          }}>
          ¿Tuviste un problema? Dejanos tu reclamo y te contactamos.
        </Typography>
        <ComplaintForm />
      </div>
    )
  },
  request: {
    content: <TeacherRequest />
  },
  faq: {
    content: <FAQ />
  }
};
const router = createBrowserRouter([
  {
    path: '/',
    element: <PageViewTracker outline={false} />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        loader: homeLoader(estudiClient),
        element: <Home />
      },
      {
        path: '/institutions/:institutionId',
        loader: homeLoader(estudiClient),
        element: <Home />
      }
    ]
  },
  {
    path: '/teachers',
    element: <PageViewTracker outline={true} />,
    children: [
      {
        path: '/teachers',
        loader: resultsLoader(estudiClient),
        element: <Results />
      },
      {
        path: '/teachers/:teacherId/profile',
        loader: teacherProfileLoader(estudiClient),
        element: <TeacherProfile />
      },
      {
        path: '/teachers/:teacherId/profile/edit',
        loader: editTeacherProfileLoader(estudiClient),
        element: (
          <AuthenticatedRoute>
            <EditTeacherProfile estudiClient={estudiClient} />
          </AuthenticatedRoute>
        )
      }
    ]
  },
  {
    path: '/reservations',
    element: <PageViewTracker outline={true} />,
    children: [
      {
        path: '/reservations/payment',
        element: <PaymentOutcome />
      }
    ]
  },
  {
    path: '/help',
    element: <PageViewTracker outline={true} />,
    children: [
      {
        path: '/help',
        element: <Help />
      },
      {
        path: '/help/claims',
        element: <HelpPage page={{ title: 'Reclamos', content: HelpPages.claims.content }} />
      },
      {
        path: '/help/request',
        element: <PageViewTracker outline={false} />,
        children: [
          {
            path: '/help/request',
            element: (
              <HelpPage
                page={{ title: 'Encontremos a tu profe ideal', content: HelpPages.request.content }}
              />
            )
          }
        ]
      },
      {
        path: '/help/faq',
        element: (
          <HelpPage page={{ title: 'Preguntas frecuentes', content: HelpPages.faq.content }} />
        )
      }
    ]
  },
  {
    path: '/about',
    element: <PageViewTracker outline={false} />,
    children: [
      {
        path: '/about',
        element: <Landing />
      }
    ]
  },
  {
    path: '/terms',
    element: <PageViewTracker outline={true} />,
    children: [
      {
        path: '/terms',
        element: <Terms />
      }
    ]
  },
  {
    path: '/privacy',
    element: <PageViewTracker outline={true} />,
    children: [
      {
        path: '/privacy',
        element: <Privacy />
      }
    ]
  },
  {
    path: '/schools',
    element: <PageViewTracker outline={false} />,
    children: [
      {
        path: '/schools',
        element: <Schools />
      }
    ]
  },
  {
    path: '/auth',
    element: <PageViewTracker outline={true} />,
    children: [
      {
        path: '/auth/login',
        element: <Login />
      }
    ]
  }
]);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <UserInteractionHistoryProvider>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
          <AuthUserProvider estudiClient={estudiClient}>
            <TeacherSearchProvider>
              <ReservationProvider>
                <StudentUserProvider>
                  <RouterProvider router={router} />
                </StudentUserProvider>
              </ReservationProvider>
            </TeacherSearchProvider>
          </AuthUserProvider>
        </GoogleOAuthProvider>
      </UserInteractionHistoryProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
