import { Await, useLoaderData, useNavigate, useRevalidator } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TeachersCalendar from '../Calendar';
import { SelectedSlot } from '../Calendar/Calendar';
import ProfileImage from '../ProfilePicture';
import './TeacherProfile.css';
import { TeacherInfo } from '../../types/TeacherInfo';
import { Course } from '../../types/Course';
import { useTeacherSearchContext } from '../../providers/TeacherSearchProvider';
import ReservationCheckout from './ReservationCheckout/ReservationCheckout';
import CoursesSection from './CoursesSection';
import { useReservationContext } from '../../providers/ReservationProvider';
import { trackClickButtonEvent } from '../../utils/functions/trackEvent';
import Chip from '@mui/material/Chip';
import SchoolIcon from '@mui/icons-material/School';
import { CalendarInfo } from '../../types/CalendarInfo';
import { Divider, Skeleton } from '@mui/material';
import Rate from '../Rate';

const groupCoursesByInstitution = (courses: Course[]): Map<string, Course[]> => {
  return courses.reduce((coursesByInstitution, course) => {
    const institutionId = course.institution.value;

    if (!coursesByInstitution.get(institutionId!)) {
      coursesByInstitution.set(institutionId!, []);
    }
    coursesByInstitution.get(institutionId!)?.push(course);

    return coursesByInstitution;
  }, new Map<string, Course[]>());
};

interface TeacherProfileLoaderResponse {
  teacher: TeacherInfo;
  calendarData: Promise<CalendarInfo>;
  course: Promise<Course | null>;
}

const TeacherProfile = () => {
  const {
    teacher,
    calendarData,
    course: coursePromise
  } = useLoaderData() as TeacherProfileLoaderResponse;
  const dictatedCoursesByInstitution: Map<string, Course[]> = groupCoursesByInstitution(
    teacher.dictated_courses
  );
  const { course: searchedCourse, institutions, setCourse } = useTeacherSearchContext();
  const [selectedCourse, setSelectedCourse] = useState<Course | undefined>(undefined);
  const [selectedCalendarSlot, setSelectedCalendarSlot] = useState<SelectedSlot | null>(null);
  const RESERVATION_STRING = teacher.reservation_type === 'purchase' ? 'Compra' : 'Reserva';
  const navigate = useNavigate();
  const { clearContext } = useReservationContext();
  const revalidator = useRevalidator();

  useEffect(() => {
    setSelectedCourse(teacher.dictated_courses.find((c) => c.value == searchedCourse?.value));
  }, [searchedCourse, teacher]);

  useEffect(() => {
    const handlePromises = async () => {
      const course = await coursePromise;

      if (course) {
        setCourse(course);
      }
    };

    void handlePromises();
  }, [coursePromise]);

  const handleCalendarReserve = (selectedSlot: any) => {
    trackClickButtonEvent('calendar_slot_confirmation');
    setSelectedCalendarSlot(selectedSlot);
    clearContext(); //FixMe: remove this clear context and fix error handling.
  };

  const handleReservationFormBack = () => {
    setSelectedCalendarSlot(null);
    revalidator.revalidate();
  };

  const handleBackToResults = () => {
    trackClickButtonEvent('back_to_results');
    if (searchedCourse) {
      navigate(`/teachers?courseId=${searchedCourse.value}`);
    } else {
      navigate(`/`);
    }
  };

  return (
    <div className="teacherProfile">
      <button className="backToResults" onClick={handleBackToResults}>
        &larr; Volver a los resultados
      </button>
      <div className="teacherInfo">
        <Card>
          <CardContent sx={{ padding: '20px' }}>
            <div className="teacherCardHeader">
              <ProfileImage image_url={teacher.image_url} size="large" />
              <Typography
                variant="h5"
                component="div"
                sx={{
                  fontSize: {
                    sm: '2rem',
                    xs: '1.5rem'
                  }
                }}
                className="teacherCardName">
                {teacher.first_name} {teacher.last_name}
                {teacher.rate && teacher.rate.average && teacher.rate.quantity && (
                  <Rate rate={teacher.rate} />
                )}
              </Typography>
              <div className="teacherTopCard">
                <h4>${teacher.price_per_hour}</h4>
                <span className="teacherProfilebyHour"> por hora</span>
              </div>
            </div>
            <div className="teacherChips">
              {teacher.dictated_classes && teacher.dictated_classes! > 0 && (
                <Chip
                  icon={<SchoolIcon />}
                  className="teacherChip"
                  label={`${teacher.dictated_classes} clases dictadas`}
                  size="small"
                  variant="outlined"
                />
              )}
              {teacher.reservation_type !== 'reservation_only' ? null : (
                <Chip
                  className="teacherChip"
                  label="Solo reserva"
                  size="small"
                  variant="outlined"
                />
              )}
            </div>
            <div className="teacherDescription">
              {teacher?.full_description ?? teacher.profile_description}
            </div>
            <Divider textAlign="left">
              <span className="configValue">MODALIDAD</span>
            </Divider>
            <ul style={{ textAlign: 'left' }}>
              {teacher.configuration.virtual_classes && (
                <li>
                  <b className="configValue">Virtual</b>
                  <br />
                  <span className="configValue">
                    {teacher.configuration.virtual_classes_message}
                  </span>
                </li>
              )}
              {teacher.configuration.in_person_classes && (
                <li>
                  <b className="configValue">Presencial</b>
                  <br />
                  <span className="configValue">
                    {teacher.configuration.in_person_classes_message}
                  </span>
                </li>
              )}
            </ul>
            <CoursesSection
              selectedCourse={selectedCourse}
              dictatedCoursesByInstitution={dictatedCoursesByInstitution}
              institutions={institutions}
            />
          </CardContent>
        </Card>
      </div>
      {selectedCalendarSlot ? (
        <React.Suspense
          fallback={
            <Skeleton
              variant="rounded"
              animation="pulse"
              width={'100%'}
              height={900}
              sx={{ marginTop: '2%' }}
            />
          }>
          <Await resolve={calendarData} errorElement={<p>Ocurrió un error cargando!</p>}>
            <ReservationCheckout
              onBack={handleReservationFormBack}
              teacher={teacher}
              start={selectedCalendarSlot.start}
              end={selectedCalendarSlot.end}
              reservationType={RESERVATION_STRING}
            />
          </Await>
        </React.Suspense>
      ) : (
        <React.Suspense
          fallback={
            <Skeleton
              variant="rounded"
              animation="pulse"
              width={'100%'}
              height={800}
              sx={{ marginTop: '2%' }}
            />
          }>
          <Await
            resolve={calendarData}
            errorElement={<p>Ocurrió un error cargando el calendario!</p>}>
            <TeachersCalendar
              businessHours={{
                daysOfWeek: teacher.available_days_of_week,
                ...teacher.business_hours
              }}
              handleConfirmation={handleCalendarReserve}
              teacherExternalId={teacher.external_id}
              reservationType={RESERVATION_STRING}
            />
          </Await>
        </React.Suspense>
      )}
    </div>
  );
};

export default TeacherProfile;
