import questions from './questions.json';
import Question from './Question';
import './FAQ.css';

const FAQ = () => {
  return (
    <div className="faqContainer">
      {questions.map((question) => (
        <Question key={`question-${question.title}`} question={question} />
      ))}
    </div>
  );
};

export default FAQ;
