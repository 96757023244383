import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import './Carousel.css';

interface CarouselProps {
  children: React.ReactNode;
}

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const checkScrollPosition = () => {
    const { scrollLeft, scrollWidth, clientWidth } = carouselRef.current!;
    const margin = 20;

    setIsAtStart(scrollLeft <= margin);
    setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - margin);
  };

  useEffect(() => {
    checkScrollPosition();
  }, [checkScrollPosition]);

  const handleScroll = (direction: 'left' | 'right') => {
    if (carouselRef.current) {
      const scrollAmount =
        direction === 'left' ? -carouselRef.current.offsetWidth : carouselRef.current.offsetWidth;

      carouselRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });

      setTimeout(checkScrollPosition, 400);
    }
  };

  return (
    <div className="carousel">
      <button
        className={cn('carousel-arrow', 'left')}
        onClick={() => handleScroll('left')}
        aria-label="Scroll left"
        disabled={isAtStart}>
        ‹
      </button>
      <div ref={carouselRef} className="carousel-viewport">
        {children}
      </div>
      <button
        className={cn('carousel-arrow', 'right')}
        onClick={() => handleScroll('right')}
        aria-label="Scroll right"
        disabled={isAtEnd}>
        ›
      </button>
    </div>
  );
};

export default Carousel;
