import { AuthToken } from '../interfaces/EstudiApiClient/dtos/AuthToken';
import { AuthApiClient } from '../interfaces/EstudiApiClient/AuthApiClient';
import { NotTeacherAccountError } from '../interfaces/EstudiApiClient/errors/NotTeacherAccountError';
import { MissingAuthTokenError } from '../interfaces/EstudiApiClient/errors/MissingAuthTokenError';
import { InvalidRefreshTokenError } from '../interfaces/EstudiApiClient/errors/InvalidRefreshTokenError';
import { InvalidVerificationCodeError } from '../interfaces/EstudiApiClient/errors/InvalidVerificationCodeError';

export class EstudiAuthApiClient implements AuthApiClient {
  private authToken: string | null;

  private onAuthError?: () => void;

  constructor() {
    this.authToken = null;
  }

  public async sendEmailVerificationCode(email: string): Promise<void> {
    const response = await fetch(`${process.env.REACT_APP_ESTUDI_API_URL}/estudi/auth/send-code`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email })
    });

    if (!response.ok) {
      if (response.status === 400) {
        throw new NotTeacherAccountError();
      }
      console.error('Error sending email verification code:', response);
      throw new Error(`Invalid api response with status ${response.status}`);
    }
  }

  public async verifyEmailCode(email: string, code: string): Promise<AuthToken> {
    const response = await fetch(
      `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/auth/verify-code`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, code }),
        credentials: 'include' // The api returns the refresh token cookies in the request
      }
    );

    if (!response.ok) {
      if (response.status === 400) {
        throw new InvalidVerificationCodeError();
      }
      console.error('Error verifying email code:', response);
      throw new Error(`Invalid api response with status ${response.status}`);
    }

    const data = await response.json();
    const { token } = data;

    this.setAuthToken(token);

    return { token };
  }

  public async verifyGoogleSSO(credential: string): Promise<AuthToken> {
    const response = await fetch(
      `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/auth/verify-google-sso`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ credential }),
        credentials: 'include' // The api returns the refresh token cookies in the request
      }
    );

    if (!response.ok) {
      if (response.status === 400) {
        throw new NotTeacherAccountError();
      }
      console.error('Error verifying google sso code:', response);
      throw new Error(`Invalid api response with status ${response.status}`);
    }

    const data = await response.json();
    const { token } = data;

    this.setAuthToken(token);

    return { token };
  }

  public async refreshAccessToken(): Promise<AuthToken> {
    const response = await fetch(
      `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/auth/refresh-token`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include' // Include refresh token cookies in the request
      }
    );

    if (!response.ok) {
      if (response.status === 400) {
        throw new InvalidRefreshTokenError();
      }
      console.error('Error refresing token:', response);
      throw new Error(`Invalid api response with status ${response.status}`);
    }

    const data = await response.json();
    const { token } = data;

    this.setAuthToken(token);

    return { token };
  }

  public async logout(): Promise<void> {
    const response = await fetch(`${process.env.REACT_APP_ESTUDI_API_URL}/estudi/auth/logout`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include' // Include refresh token cookies in the request
    });

    if (!response.ok) {
      console.error('Error on logout', response);
      throw new Error(`Invalid api response with status ${response.status}`);
    }
    this.setAuthToken(null);
  }

  private setAuthToken(token: string | null): void {
    this.authToken = token;
  }

  public setOnAuthError(callback: () => void): void {
    this.onAuthError = callback;
  }

  public async fetchWithAuth(url: string, options: RequestInit = {}): Promise<Response> {
    const headers = options?.headers ? new Headers(options.headers) : new Headers();

    if (!this.authToken) {
      this.onAuthError?.();
      throw new MissingAuthTokenError(url);
    }

    headers.set('Authorization', `Bearer ${this.authToken}`);

    const response = await fetch(url, {
      ...options,
      headers
    });

    if (response.status === 401) {
      await this.refreshAuthAndFetch(url, options);
    }

    return response;
  }

  private async refreshAuthAndFetch(url: string, options: RequestInit = {}): Promise<Response> {
    try {
      await this.refreshAccessToken();
    } catch (error: unknown) {
      this.onAuthError?.();
      throw new MissingAuthTokenError(url);
    }

    const headers = options?.headers ? new Headers(options.headers) : new Headers();

    headers.set('Authorization', `Bearer ${this.authToken}`);

    return fetch(url, {
      ...options,
      headers
    });
  }
}
