import { EstudiApiClient } from '../../services/interfaces/EstudiApiClient/EstudiApiClient';
import { Course } from '../../types/Course';

export const loader =
  (estudiClient: EstudiApiClient) =>
  async ({ params }: { params: Record<string, string | undefined> }) => {
    const institutionId: string | undefined = params.institutionId;
    const institutions = await estudiClient.getInstitutions();

    const selectedInstitution = institutions.find(
      (institution) => institution.value === institutionId
    );

    let courses: Course[] | undefined = undefined;

    if (selectedInstitution && institutionId) {
      courses = await estudiClient.getCourses({ institutionId });
    }

    return { courses, institutions, institution: selectedInstitution };
  };
