import { defer, redirect } from 'react-router-dom';
import { EstudiApiClient } from '../../services/interfaces/EstudiApiClient/EstudiApiClient';
import { Course } from '../../types/Course';

export const loader =
  (estudiClient: EstudiApiClient) =>
  async ({
    request,
    params: pathParams
  }: {
    request: { url: string };
    params: Record<string, string | undefined>;
  }) => {
    if (!pathParams.teacherId) {
      return redirect('/');
    }

    const teacherProfile = await estudiClient.getTeacherProfile({
      teacherId: pathParams.teacherId
    });
    const teacherCalendarPromise = estudiClient.getTeacherCalendar({
      teacherId: pathParams.teacherId
    });

    const url = new URL(request.url);
    const courseId = url.searchParams.get('courseId');

    let coursePromise: Promise<Course | null> = Promise.resolve(null);

    if (courseId && teacherProfile.dictated_courses.find((course) => course.value === courseId)) {
      coursePromise = estudiClient.getCourseById({
        courseId: courseId
      });
    }

    return defer({
      teacher: teacherProfile,
      course: coursePromise,
      calendarData: teacherCalendarPromise
    });
  };
