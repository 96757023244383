import { Await, useLoaderData, useNavigate } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { useAuthUserContext } from '../../../providers/AuthUserProvider';
import { TeacherInfo } from '../../../types/TeacherInfo';
import { EstudiApiClient } from '../../../services/interfaces/EstudiApiClient/EstudiApiClient';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Checkbox, Divider, FormControlLabel, FormHelperText, Skeleton } from '@mui/material';
import ProfileImage from '../../ProfilePicture';
import Rate from '../../Rate';
import './EditTeacherProfile.css';
import { UpdateTeacherInfo } from '../../../types/UpdateTeacherInfo';
import EditCoursesSelector from './EditCoursesSelector';
import { Course } from '../../../types/Course';
import { Institution } from '../../../types/Institution';

interface EditTeacherProfileLoaderResponse {
  teacher: TeacherInfo;
  institutions: Promise<Institution[]>;
  coursesByInstitution: Promise<Record<string, Course[]>>;
}

const EditTeacherProfile: React.FC<{ estudiClient: EstudiApiClient }> = ({ estudiClient }) => {
  const { user } = useAuthUserContext();
  const { teacher, institutions, coursesByInstitution } =
    useLoaderData() as EditTeacherProfileLoaderResponse;
  const [formData, setFormData] = useState<UpdateTeacherInfo>({});
  const navigate = useNavigate();

  useEffect(() => {
    setFormData({
      profile_description: teacher.profile_description,
      full_description: teacher.full_description,
      available_days_of_week: teacher.available_days_of_week,
      price_per_hour: teacher.price_per_hour,
      dictated_courses: teacher.dictated_courses,
      configuration: teacher.configuration
    });
  }, [teacher]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    isNumeric = false
  ) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: isNumeric ? parseInt(value) : value
    }));
  };

  const handleInputConfigurationChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    isNumeric = false
  ) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      configuration: {
        ...teacher.configuration,
        ...prev.configuration,
        [name]: isNumeric ? parseInt(value) : value
      }
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;

    setFormData((prev) => ({
      ...prev,
      configuration: {
        ...teacher.configuration,
        ...prev.configuration,
        [name]: checked
      }
    }));
  };

  const handleCoursesChange = (updatedCourses: Course[]) => {
    setFormData((prev) => ({
      ...prev,
      dictated_courses: updatedCourses
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (user?.id) {
        await estudiClient.updateTeacherProfile({ teacherId: user.id, profileData: formData });

        navigate(`/teachers/${user.id}/profile`);
      }
    } catch (error: unknown) {
      console.error('Error updating teacher profile:', error);
      alert('Hubo un error actualizando el perfil.');
    }
  };

  return (
    <div className="editTeacherProfile">
      <h1>Edita tu Perfil</h1>
      <form onSubmit={handleSubmit} className="editForm">
        {/* Personal information */}
        <div className="teacherPersonalInfo">
          <Card>
            <CardContent sx={{ padding: '20px' }}>
              <div className="teacherCardHeader">
                <ProfileImage image_url={teacher.image_url} size="large" />
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    fontSize: {
                      sm: '2rem',
                      xs: '1.5rem'
                    }
                  }}
                  className="teacherCardName">
                  {teacher.first_name} {teacher.last_name}
                  {teacher.rate && teacher.rate.average && teacher.rate.quantity && (
                    <Rate rate={teacher.rate} />
                  )}
                </Typography>
              </div>
              <Divider sx={{ margin: '20px 0' }}>Información Personal</Divider>
              <TextField
                label="Descripción Resumen"
                name="profile_description"
                value={formData.profile_description || ''}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
                disabled={true}
              />
              <TextField
                label="Descripción Completa"
                name="full_description"
                value={formData.full_description || ''}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
                margin="normal"
                disabled={true}
              />
              <TextField
                label="Precio por hora"
                name="price_per_hour"
                type="number"
                value={formData.price_per_hour || ''}
                onChange={(e) => handleInputChange(e, true)}
                fullWidth
                margin="normal"
              />
            </CardContent>
          </Card>
        </div>

        {/* Classes configuration */}
        <div className="teacherConfiguration">
          <Card>
            <CardContent sx={{ padding: '20px' }}>
              <Divider sx={{ margin: '20px 0' }}>Configuración de Clases</Divider>
              <FormControlLabel
                control={
                  <Checkbox
                    name="virtual_classes"
                    checked={formData.configuration?.virtual_classes || false}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Cómo das las clases virtuales?"
              />
              <TextField
                label="Por ejemplo, podes contar que dias"
                name="virtual_classes_message"
                value={formData.configuration?.virtual_classes_message || ''}
                onChange={handleInputConfigurationChange}
                disabled={!formData.configuration?.virtual_classes}
                fullWidth
                margin="normal"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="in_person_classes"
                    checked={formData.configuration?.in_person_classes || false}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Cómo das las clases presenciales?"
              />
              <TextField
                label="Por ejemplo, podes contar donde y que dias"
                name="in_person_classes_message"
                value={formData.configuration?.in_person_classes_message || ''}
                onChange={handleInputConfigurationChange}
                disabled={!formData.configuration?.in_person_classes}
                fullWidth
                margin="normal"
              />

              <TextField
                label="Semanas en adelante disponibles en el calendario"
                name="weeks_in_advance_for_reserve"
                type="number"
                value={formData.configuration?.weeks_in_advance_for_reserve || ''}
                onChange={(e) => handleInputConfigurationChange(e, true)}
                fullWidth
                margin="normal"
              />
            </CardContent>
          </Card>
        </div>

        {/* Dictated courses */}
        <div className="teacherDictatedCourses">
          <Card>
            <CardContent sx={{ padding: '20px' }}>
              <Divider sx={{ margin: '20px 0' }}>Materias Dictadas</Divider>
              <React.Suspense
                fallback={
                  <Skeleton
                    variant="rounded"
                    animation="pulse"
                    width={'100%'}
                    height={800}
                    sx={{ marginTop: '2%' }}
                  />
                }>
                <Await
                  resolve={useMemo(() => Promise.all([institutions, coursesByInstitution]), [])}
                  errorElement={<p>Ocurrió un error cargando los datos de materias.</p>}>
                  {([loadedInstitutions, loadedCoursesByInstitution]: [
                    Institution[],
                    Record<string, Course[]>
                  ]) => (
                    <div className="editCoursesForm">
                      <FormHelperText>
                        <a
                          href="https://drive.google.com/drive/folders/1txRK2dXrJl0RxSskR8P3twOry1TUoiQk"
                          target="_blank"
                          rel="noopener noreferrer">
                          Acá
                        </a>{' '}
                        podrás ver material que hemos recopilado de las distintas materias para que
                        puedas evaluar si podes dar dicha materia. <br /> Marca las materias que
                        dictas, organizadas por institución. También puedes agregar notas
                        adicionales para cada materia.
                      </FormHelperText>
                      <EditCoursesSelector
                        institutions={loadedInstitutions}
                        coursesByInstitution={new Map(Object.entries(loadedCoursesByInstitution))}
                        dictatedCourses={teacher.dictated_courses}
                        onChange={handleCoursesChange}
                      />
                    </div>
                  )}
                </Await>
              </React.Suspense>
            </CardContent>
          </Card>
        </div>

        {/* Save button */}
        <Button type="submit" variant="contained" color="primary" sx={{ marginTop: '20px' }}>
          Guardar Cambios
        </Button>
      </form>
    </div>
  );
};

export default EditTeacherProfile;
