import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

import { Institution } from '../types/Institution';
import { Course } from '../types/Course';
import { coursesQueryFunction } from '../services/adapters/queries/coursesQueryFunction';
import { useQuery } from 'react-query';
import { institutionsQueryFunction } from '../services/adapters/queries/institutionsQueryFunction';

interface TeacherSearchContextProps {
  course: Course | undefined;
  courses: Course[] | undefined;
  setCourse: (course: Course | undefined) => void;
  coursesLoading: boolean;
  institution: Institution | undefined;
  institutions: Institution[] | undefined;
  setInstitution: (institution: Institution | undefined) => void;
  institutionsLoading: boolean;
}

const TeacherSearchContext = createContext<TeacherSearchContextProps>({
  course: undefined,
  courses: undefined,
  coursesLoading: false,
  institution: undefined,
  institutions: undefined,
  institutionsLoading: false,
  setCourse: () => {},
  setInstitution: () => {}
});

const useInstitutionsQuery = () => {
  const institutionsQuery = institutionsQueryFunction();
  const { data: institutions, isLoading: institutionsLoading } = useQuery<Institution[]>(
    institutionsQuery.queryKey,
    institutionsQuery.queryFn
  );

  return { institutions, institutionsLoading };
};

const useCoursesQuery = (institutionIdParam: string) => {
  const [institutionId, setInstitutionId] = useState(institutionIdParam);

  const query = coursesQueryFunction(institutionId);
  const { data: courses, isLoading: coursesLoading } = useQuery<Course[]>(
    query.queryKey,
    query.queryFn
  );

  return useMemo(
    () => ({ courses, coursesLoading, setInstitutionId }),
    [setInstitutionId, courses]
  );
};

export const useTeacherSearchContext = () => useContext(TeacherSearchContext);

interface TeacherSearchProviderProps {
  children: ReactNode;
}

export const TeacherSearchProvider: React.FC<TeacherSearchProviderProps> = ({
  children
}: TeacherSearchProviderProps) => {
  const [course, setCourse] = useState<Course | undefined>();
  const [institution, setInstitution] = useState<Institution | undefined>();
  const {
    courses,
    coursesLoading,
    setInstitutionId: setCoursesInstitutionId
  } = useCoursesQuery(institution?.value ?? '');
  const { institutions, institutionsLoading } = useInstitutionsQuery();

  const handleSetInstitution = (institutionToSet: Institution | undefined) => {
    setInstitution(institutionToSet);
    setCoursesInstitutionId(institutionToSet?.value ?? '');
  };

  const value = useMemo(
    () => ({
      course,
      courses: institution ? courses : undefined,
      setCourse,
      coursesLoading: institution ? coursesLoading : false,
      institution,
      institutions,
      setInstitution: handleSetInstitution,
      institutionsLoading
    }),
    [
      course,
      courses,
      setCourse,
      coursesLoading,
      institution,
      institutions,
      setInstitution,
      institutionsLoading
    ]
  );

  return <TeacherSearchContext.Provider value={value}>{children}</TeacherSearchContext.Provider>;
};

export default TeacherSearchProvider;
