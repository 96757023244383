import { jwtDecode } from 'jwt-decode';
import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { User } from '../types/User';
import { EstudiApiClient } from '../services/interfaces/EstudiApiClient/EstudiApiClient';
import { AuthToken } from '../services/interfaces/EstudiApiClient/dtos/AuthToken';
import { EstudiAuthTokenClaims } from '../types/EstudiAuthTokenClaims';
import { userAdapter } from '../services/adapters/mappers/users';

interface AuthUserContextProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | undefined;
  loginWithEmailCode: (email: string) => Promise<void>;
  verifyEmailCode: (email: string, code: string) => Promise<void>;
  verifyGoogleSSO: (credential: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthUserContext = createContext<AuthUserContextProps>({
  isAuthenticated: false,
  isLoading: false,
  user: undefined,
  loginWithEmailCode: () => Promise.reject(),
  verifyEmailCode: () => Promise.reject(),
  verifyGoogleSSO: () => Promise.reject(),
  logout: () => Promise.reject()
});

export const useAuthUserContext = () => useContext(AuthUserContext);

interface AuthUserProviderProps {
  children: ReactNode;
  estudiClient: EstudiApiClient;
}

export const AuthUserProvider: React.FC<AuthUserProviderProps> = ({ children, estudiClient }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | undefined>(undefined);
  const refreshTokenTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const logout = async (): Promise<void> => {
    await estudiClient.auth.logout();

    setUser(undefined);
    setIsAuthenticated(false);
    setIsLoading(false);

    if (refreshTokenTimeoutRef.current) {
      clearTimeout(refreshTokenTimeoutRef.current);
    }
  };

  const loginWithEmailCode = async (email: string): Promise<void> => {
    await estudiClient.auth.sendEmailVerificationCode(email);
  };

  useEffect(() => {
    estudiClient.auth.setOnAuthError(logout);
  }, [estudiClient]);

  const scheduleTokenRefresh = (expiresIn: number): void => {
    // Refresh 1 min before it expires
    const refreshTime = (expiresIn - 60) * 1000;

    if (refreshTokenTimeoutRef.current) {
      clearTimeout(refreshTokenTimeoutRef.current);
    }

    refreshTokenTimeoutRef.current = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      void checkAuth();
    }, refreshTime);
  };

  const handleSuccessfulAuthentication = (userAuthToken: AuthToken): User => {
    const decodedToken = jwtDecode<EstudiAuthTokenClaims>(userAuthToken.token);

    const userDecoded = userAdapter(decodedToken);

    setUser(userDecoded);
    setIsAuthenticated(true);

    if (decodedToken.exp) {
      scheduleTokenRefresh((decodedToken.exp * 1000 - Date.now()) / 1000);
    } else {
      scheduleTokenRefresh(15 * 60);
    }

    return userDecoded;
  };

  const verifyEmailCode = async (email: string, code: string): Promise<void> => {
    const userAuthToken = await estudiClient.auth.verifyEmailCode(email, code);

    handleSuccessfulAuthentication(userAuthToken);
  };

  const verifyGoogleSSO = async (credential: string): Promise<void> => {
    const userAuthToken = await estudiClient.auth.verifyGoogleSSO(credential);

    handleSuccessfulAuthentication(userAuthToken);
  };

  const checkAuth = async (): Promise<void> => {
    try {
      const userAuthToken = await estudiClient.auth.refreshAccessToken();

      handleSuccessfulAuthentication(userAuthToken);
    } catch (error: unknown) {
      await logout();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user) {
      void checkAuth();
    }
  }, [estudiClient, user]);

  return (
    <AuthUserContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        user,
        loginWithEmailCode,
        verifyEmailCode,
        verifyGoogleSSO,
        logout
      }}>
      {children}
    </AuthUserContext.Provider>
  );
};

export default AuthUserProvider;
