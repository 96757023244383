import { User } from '../../../types/User';
import { EstudiAuthTokenClaims } from '../../../types/EstudiAuthTokenClaims';

export function userAdapter(claims: EstudiAuthTokenClaims): User {
  return {
    id: claims.userId,
    email: claims.userEmail,
    firstName: claims.userFirstName,
    lastName: claims.userLastName,
    roles: JSON.parse(claims.userRoles)
  };
}
