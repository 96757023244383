import { Institution } from '../../types/Institution';
import { Course } from '../../types/Course';
import { QueryClient } from 'react-query';
import { ensureQuery } from '../../utils/functions/ensureQuery';
import { EstudiApiClient } from '../interfaces/EstudiApiClient/EstudiApiClient';
import { Teacher } from '../../types/Teacher';
import { TeacherInfo } from '../../types/TeacherInfo';
import { institutionsQueryFunction } from './queries/institutionsQueryFunction';
import { coursesQueryFunction } from './queries/coursesQueryFunction';
import { teachersQueryFunction } from './queries/teachersQueryFunction';
import { teacherProfileQueryFunction } from './queries/teacherProfileQueryFunction';
import { CalendarInfo } from '../../types/CalendarInfo';
import { courseQueryFunction } from './queries/courseQueryFunction';
import { AuthApiClient } from '../interfaces/EstudiApiClient/AuthApiClient';
import { UpdateTeacherInfo } from '../../types/UpdateTeacherInfo';

export class EstudiApiQueryClient implements EstudiApiClient {
  public readonly auth: AuthApiClient;

  private readonly queryClient: QueryClient;

  constructor(queryClient: QueryClient, authClient: AuthApiClient) {
    this.queryClient = queryClient;
    this.auth = authClient;
  }

  public async getInstitutionById(params: { institutionId: string }): Promise<Institution | null> {
    //FixMe: when the API exposes the get by id, change this implementation
    return this.getInstitutions().then(
      (institutions) =>
        institutions.find((institution) => institution.value === params.institutionId) ?? null
    );
  }

  public async getInstitutions(): Promise<Institution[]> {
    const institutionsQuery = institutionsQueryFunction();

    return ensureQuery(this.queryClient, institutionsQuery);
  }

  public async getCourseById(params: { courseId: string }): Promise<Course | null> {
    const courseQuery = courseQueryFunction(params.courseId);

    return ensureQuery(this.queryClient, courseQuery);
  }

  public async getCourses(params?: { institutionId?: string }): Promise<Course[]> {
    const coursesQuery = coursesQueryFunction(params?.institutionId);

    return ensureQuery(this.queryClient, coursesQuery);
  }

  public async getTeachers(params: {
    institutionId: string;
    courseId: string;
  }): Promise<Teacher[]> {
    const teachersQuery = teachersQueryFunction(params.institutionId, params.courseId);

    return ensureQuery(this.queryClient, teachersQuery);
  }

  public async getTeacherProfile(params: { teacherId: string }): Promise<TeacherInfo> {
    const teacherProfileQuery = teacherProfileQueryFunction(params.teacherId);

    return ensureQuery(this.queryClient, teacherProfileQuery);
  }

  public async getTeacherCalendar(params: { teacherId: string }): Promise<CalendarInfo> {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/web/teachers/${params.teacherId}/profile/calendar`
      );

      return await response.json();
    } catch (error: unknown) {
      console.error(error);

      throw error;
    }
  }

  public async updateTeacherProfile(params: {
    teacherId: string;
    profileData: UpdateTeacherInfo;
  }): Promise<void> {
    try {
      const response = await this.auth.fetchWithAuth(
        `${process.env.REACT_APP_ESTUDI_API_URL}/estudi/web/teachers/${params.teacherId}/profile/update`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params.profileData)
        }
      );

      if (!response.ok) {
        throw new Error(`Error updating teacher profile: ${response.statusText}`);
      }

      await this.queryClient.refetchQueries([params.teacherId, 'teacher-profile']);
    } catch (error: unknown) {
      console.error(error);
      throw error;
    }
  }
}
