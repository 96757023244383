import { defer, redirect } from 'react-router-dom';
import { EstudiApiClient } from '../../../services/interfaces/EstudiApiClient/EstudiApiClient';
import { coursesByInstitutionAdapter } from '../../../services/adapters/mappers/coursesByInstitution';

export const loader =
  (estudiClient: EstudiApiClient) =>
  async ({ params: pathParams }: { params: Record<string, string | undefined> }) => {
    if (!pathParams.teacherId) {
      return redirect('/');
    }

    const teacherProfile = await estudiClient.getTeacherProfile({
      teacherId: pathParams.teacherId
    });

    const institutionsPromise = estudiClient.getInstitutions();
    const coursesPromise = estudiClient.getCourses();
    const coursesByInstitutionPromise = coursesPromise.then((courses) =>
      Object.fromEntries(coursesByInstitutionAdapter(courses))
    );

    return defer({
      teacher: teacherProfile,
      institutions: institutionsPromise,
      coursesByInstitution: coursesByInstitutionPromise
    });
  };
