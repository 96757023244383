import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import step1 from '../../../resources/images/estudi-step1.png';
import step2 from '../../../resources/images/estudi-step2.png';
import step3 from '../../../resources/images/estudi-step3.png';
import step4 from '../../../resources/images/estudi-step4.png';
import CardMedia from '@mui/material/CardMedia';
import './Tutorial.css';

const Tutorial = () => {
  const steps = [
    {
      number: 1,
      title: 'Elegi universidad y materia',
      description:
        'Selecciona la universidad en la que estudias y la materia en la que necesitas ayuda',
      img: step1
    },
    {
      number: 2,
      title: 'Elegi tu profe',
      description: 'Encontrá profes que tienen experiencia dando tu materia para tu universidad',
      img: step2
    },
    {
      number: 3,
      title: 'Elegi día y horario',
      description:
        'Reserva tu primera clase en el horario que mejor te convenga, consultando la disponibilidad de cada profesor.',
      img: step3
    },
    {
      number: 4,
      title: 'Comprá y conectá',
      description:
        'Completá tus datos para luego hacer el pago y ¡listo! Crearemos un grupo de WhatsApp para que puedas comunicarte con tu profe y contarle tus necesidades.',
      img: step4
    }
  ];

  return (
    <div className="tutorial-container">
      <h1>¿Cómo funciona?</h1>
      <div className="tutorial">
        {steps.map((step) => (
          <Card key={step.number} className="bn-card" sx={{ maxWidth: 345 }}>
            <CardMedia component="img" width="10" image={step.img} alt="Step" />
            <CardContent>
              <Typography sx={{ fontSize: 14 }} color="#184b76" gutterBottom>
                {step.number}
              </Typography>
              <Typography variant="h5" component="div">
                {step.title}
              </Typography>
              <Typography variant="body2">{step.description}</Typography>
            </CardContent>
          </Card>
        ))}
      </div>
      <span className="faq">
        Para más información lee nuestras&nbsp;<a href="/help/faq">preguntas frecuentes</a>
      </span>
    </div>
  );
};

export default Tutorial;
