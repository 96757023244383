export enum UserRolName {
  TEACHER = 'TEACHER'
}

export interface UserRol {
  name: UserRolName;
  permissions: string[];
}

export interface User {
  id: string;
  email: string;
  roles: UserRol[];
  firstName: string;
  lastName: string;
}
