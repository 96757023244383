import './Banner.css';
import { ButtonPaddingSize } from '../../Button/Button.types';
import { ButtonColor, ButtonLink } from '../../Button';
import SchoolIcon from '@mui/icons-material/School';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Fragment } from 'react';

const Banner = () => {
  return (
    <Fragment>
      {/* Why choose us section */}
      <section className="whyChooseUs">
        <div className="whyChooseUsContainer">
          <h2 className="whyChooseUsTitle">
            ¿Por qué elegir <span className="highlight">estudi</span>?
          </h2>
          <div className="featuresGrid">
            <div className="featureCard">
              <SchoolIcon className="icon" sx={{ fontSize: { sm: '5rem', xs: '4rem' } }} />
              <h3 className="featureTitle">Profesores Verificados</h3>
              <p className="featureDescription">
                Todos nuestros profesores son estudiantes avanzados o graduados universitarios.
              </p>
            </div>
            <div className="featureCard">
              <MenuBookIcon className="icon" sx={{ fontSize: { sm: '5rem', xs: '4rem' } }} />
              <h3 className="featureTitle">Especialización</h3>
              <p className="featureDescription">Aprende con profesores expertos en tus materias.</p>
            </div>
            <div className="featureCard">
              <AccessTimeIcon className="icon" sx={{ fontSize: { sm: '5rem', xs: '4rem' } }} />
              <h3 className="featureTitle">Flexibilidad</h3>
              <p className="featureDescription">
                Elige el horario que mejor se adapte a tu agenda de estudio.
              </p>
            </div>
            <div className="featureCard">
              <VerifiedIcon className="icon" sx={{ fontSize: { sm: '5rem', xs: '4rem' } }} />
              <h3 className="featureTitle">Garantía de Satisfacción</h3>
              <p className="featureDescription">
                Si no estás satisfecho con tu clase, te devolvemos el dinero.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Learn more about us section */}
      <section className="aboutUs">
        <div className="aboutUsContainer">
          <p className="sectionTitle">
            Mejora tu rendimiento académico
            <span className="highlightContainer">
              <span className="highlight">hoy</span>
            </span>{' '}
          </p>
          <p className="aboutUsDescription">
            Unite a los miles de estudiantes que ya han mejorado su aprendizaje y sus calificaciones
            con estudi
          </p>
          <ButtonLink
            href="/about"
            color={ButtonColor.SECONDARY}
            paddingSize={ButtonPaddingSize.MD}>
            Conocer más
          </ButtonLink>
        </div>
      </section>
    </Fragment>
  );
};

export default Banner;
