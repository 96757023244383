import React, { Fragment } from 'react';
import './Footer.css';
import IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ReactComponent as TikTokIcon } from './../../resources/icons/TikTokIcon.svg';
import { Link, ScrollRestoration } from 'react-router-dom';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Fragment>
      <footer className="footer">
        <div className="footer-container">
          {/* General links */}
          <div className="footer-column">
            <h4>Enlaces</h4>
            <ul>
              <li>
                <Link to={'/about'}>Sobre nosotros</Link>
              </li>
              <li>
                <Link to={'/help'}>Ayuda</Link>
              </li>
              <li>
                <Link to={'/help'}>Contacto</Link>
              </li>
            </ul>
          </div>

          {/* Legal information */}
          <div className="footer-column">
            <h4>Legal</h4>
            <ul>
              <li>
                <Link to={'/privacy'}>Privacidad</Link>
              </li>
              <li>
                <Link to={'/terms'}>Términos y condiciones</Link>
              </li>
            </ul>
          </div>

          {/* Social links */}
          <div className="footer-column">
            <h4>Síguenos</h4>
            <div className="social-icons">
              <IconButton>
                <a
                  href="https://instagram.com/estudi.clases"
                  target="_blank"
                  rel="noopener noreferrer">
                  <InstagramIcon fontSize="large" sx={{ color: '#184b76' }} />
                </a>
              </IconButton>
              <IconButton>
                <a href="https://wa.me/1161297377" target="_blank" rel="noopener noreferrer">
                  <WhatsAppIcon fontSize="large" sx={{ color: '#184b76' }} />
                </a>
              </IconButton>
              <IconButton>
                <a
                  href="https://www.tiktok.com/@estudi.clases"
                  target="_blank"
                  rel="noopener noreferrer">
                  <TikTokIcon style={{ fill: '#184b76', width: '35px', height: '35px' }} />
                </a>
              </IconButton>
            </div>
          </div>
        </div>

        {/* Logo and description */}
        <div className="footer-bottom">
          <h3 className="footer-logo">estudi</h3>
          <p>© {currentYear} estudi. Todos los derechos reservados.</p>
        </div>
      </footer>
      <ScrollRestoration />
    </Fragment>
  );
};

export default Footer;
