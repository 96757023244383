import React from 'react';
import ButtonBase from './ButtonBase';
import { ButtonLinkProps } from './Button.types';

const ButtonLink: React.FC<ButtonLinkProps> = ({ href, target, rel, ...props }) => {
  const isExternal = /^(http|https):\/\//.test(href);

  return (
    <ButtonBase
      href={href}
      isExternal={isExternal}
      target={target ? target : isExternal ? '_blank' : '_self'}
      rel={rel ? rel : isExternal ? 'noopener noreferrer' : undefined}
      {...props}
    />
  );
};

export default ButtonLink;
