import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { CreateReservation } from '../types/CreateReservation';
import { Course } from '../types/Course';
import { TeacherInfo } from '../types/TeacherInfo';

interface ReservationIntent {
  reservation: CreateReservation;
  teacher: TeacherInfo;
  course: Course;
}

interface UserInteractionHistoryContextProps {
  reservationIntents: ReservationIntent[];
  addReservationIntent: (
    reservation: CreateReservation,
    teacher: TeacherInfo,
    course: Course
  ) => void;
}

const UserInteractionHistoryContext = createContext<UserInteractionHistoryContextProps>({
  reservationIntents: [],
  addReservationIntent: () => {}
});

export const useUserInteractionHistoryContext = () => useContext(UserInteractionHistoryContext);

interface UserInteractionHistoryProviderProps {
  children: ReactNode;
}

export const UserInteractionHistoryProvider: React.FC<UserInteractionHistoryProviderProps> = ({
  children
}: UserInteractionHistoryProviderProps) => {
  const [reservationIntents, setReservationIntents] = useState<ReservationIntent[]>([]);
  const RESERVATION_INTENTS_LOCAL_STORAGE_KEY = 'userReservationIntents';

  useEffect(() => {
    const storedReservationIntents = localStorage.getItem(RESERVATION_INTENTS_LOCAL_STORAGE_KEY);

    if (storedReservationIntents) {
      setReservationIntents(JSON.parse(storedReservationIntents));
    }
  }, []);

  const addReservationIntent = (
    reservation: CreateReservation,
    teacher: TeacherInfo,
    course: Course
  ) => {
    setReservationIntents((prevReservations) => {
      const updatedInteractions = [{ reservation, teacher, course }, ...prevReservations];

      localStorage.setItem(
        RESERVATION_INTENTS_LOCAL_STORAGE_KEY,
        JSON.stringify(updatedInteractions)
      );

      return updatedInteractions;
    });
  };

  return (
    <UserInteractionHistoryContext.Provider value={{ reservationIntents, addReservationIntent }}>
      {children}
    </UserInteractionHistoryContext.Provider>
  );
};

export default UserInteractionHistoryProvider;
