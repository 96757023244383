import { ReactNode } from 'react';

export enum ButtonColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error'
}

export enum ButtonVariant {
  CONTAINED = 'contained',
  OUTLINED = 'outlined',
  TEXT = 'text'
}

export enum ButtonSize {
  SM = 'size-small',
  MD = 'size-medium',
  LG = 'size-large'
}

export enum ButtonPaddingSize {
  SM = 'padding-small',
  MD = 'padding-medium',
  LG = 'padding-large'
}

export interface BaseButtonProps {
  children: ReactNode;
  color?: ButtonColor;
  variant?: ButtonVariant;
  paddingSize?: ButtonPaddingSize;
  size?: ButtonSize;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
}

export interface ButtonProps extends BaseButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  type?: string;
}

export interface ButtonLinkProps extends BaseButtonProps {
  href: string;
  target?: '_blank' | '_self';
  rel?: 'noopener noreferrer';
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}
