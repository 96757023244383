import React, { useEffect, useState } from 'react';
import { useAuthUserContext } from '../../providers/AuthUserProvider';
import { useNavigate } from 'react-router-dom';
import { UserRolName } from '../../types/User';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import TextField from '@mui/material/TextField';
import { NotTeacherAccountError } from '../../services/interfaces/EstudiApiClient/errors/NotTeacherAccountError';
import { InvalidVerificationCodeError } from '../../services/interfaces/EstudiApiClient/errors/InvalidVerificationCodeError';
import { Divider } from '@mui/material';
import { Button, ButtonColor, ButtonLink, ButtonVariant } from '../Button';
import './Login.css';

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { isAuthenticated, user, loginWithEmailCode, verifyEmailCode, verifyGoogleSSO } =
    useAuthUserContext();
  const navigate = useNavigate();
  const googleSSOEnabled = false;
  const [loading, setLoading] = useState(false);

  const handleSendCode = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);
      await loginWithEmailCode(email);
      setIsCodeSent(true);
      setErrorMessage('');
      setLoading(false);
    } catch (error: unknown) {
      if (error instanceof NotTeacherAccountError) {
        setErrorMessage('El email no pertenece a una cuenta de profesor.');
        setLoading(false);

        return;
      }
      setErrorMessage('El servicio se encuentra temporalmente fuera de servicio.');
      setLoading(false);
      console.error('Error sending verification code:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      if (user?.roles.find((rol) => rol.name === UserRolName.TEACHER)) {
        navigate(`/teachers/${user.id}/profile/edit`);
      } else {
        // Add more user types
        navigate('/');
      }
    }
  }, [isAuthenticated, user]);

  const handleVerifyCode = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await verifyEmailCode(email, verificationCode);

      setErrorMessage('');
      setLoading(false);
    } catch (error: unknown) {
      if (error instanceof InvalidVerificationCodeError) {
        setErrorMessage('El codigo ingresado es invalido, busca el correcto en tu correo.');
        setLoading(false);

        return;
      }

      setErrorMessage('Error al verificar el código.');
      setLoading(false);
      console.error('Error verifying code:', error);
    }
  };

  const handleGoogleLoginSuccess = async (response: CredentialResponse) => {
    if (!response.credential) {
      setErrorMessage('Error al iniciar sesión con Google.');
      console.error('Error logging in with Google: no token id present');

      return;
    }

    try {
      await verifyGoogleSSO(response.credential);

      setErrorMessage('');
    } catch (error: unknown) {
      if (error instanceof NotTeacherAccountError) {
        setErrorMessage('El email no pertenece a una cuenta de profesor.');

        return;
      }

      setErrorMessage('El servicio se encuentra temporalmente fuera de servicio.');
      console.error('Error logging in with Google:', error);
    }
  };

  const handleGoogleLoginFailure = () => {
    setErrorMessage('Error al iniciar sesión con Google.');
    console.error('Google Login Failed');
  };

  const handleBack = () => {
    setLoading(false);
    setIsCodeSent(false);
    setErrorMessage('');
  };

  return (
    <div className="loginPage">
      <div className="loginCard">
        <h2>Accede a tu cuenta de profesor</h2>
        <form onSubmit={isCodeSent ? handleVerifyCode : handleSendCode}>
          {errorMessage && <p className="authError">{errorMessage}</p>}
          {!isCodeSent ? (
            <div className="loginForm">
              <TextField
                id="login-email"
                key="email"
                placeholder="Ingresa tu email"
                variant="outlined"
                margin="dense"
                required
                type="email"
                value={email}
                onChange={(e: any) => setEmail(e.target.value)}
                className="loginInput"
              />
              <Button
                variant={ButtonVariant.CONTAINED}
                color={ButtonColor.SECONDARY}
                type="submit"
                disabled={!email}
                isLoading={loading}
                className="loginButton">
                Enviar código de verificación
              </Button>
              <Divider sx={{ width: '100%' }} />
              <span>O si todavia no sos parte</span>
              <ButtonLink
                variant={ButtonVariant.OUTLINED}
                color={ButtonColor.SECONDARY}
                className="loginButton"
                href="https://teachers.estudi.com.ar/"
                target="_blank"
                rel="noopener noreferrer">
                Sumate como profe
              </ButtonLink>
            </div>
          ) : (
            <div className="loginForm">
              <p>Se ha enviado un código de verificación a {email}</p>
              <TextField
                id="login-verification-code"
                type="text"
                placeholder="Ingresa el código de verificación"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                required
                className="loginInput"
              />
              <Button
                variant={ButtonVariant.CONTAINED}
                color={ButtonColor.SECONDARY}
                className="loginButton"
                type="submit"
                disabled={!verificationCode}>
                Verificar código
              </Button>
              <Button
                variant={ButtonVariant.OUTLINED}
                color={ButtonColor.SECONDARY}
                className="loginButton"
                onClick={handleBack}>
                Volver a enviar código
              </Button>
            </div>
          )}
        </form>
        {googleSSOEnabled && (
          <div className="ssoLogin">
            <p>O ingresa con</p>
            <div className="googleLoginButton">
              <GoogleLogin
                text="signin_with"
                onSuccess={handleGoogleLoginSuccess}
                onError={handleGoogleLoginFailure}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
