import React from 'react';
import cn from 'classnames';
import './Button.css';
import {
  ButtonLinkProps,
  ButtonPaddingSize,
  ButtonProps,
  ButtonSize,
  ButtonColor,
  ButtonVariant
} from './Button.types';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

type ButtonBaseProps = (ButtonProps | ButtonLinkProps) & {
  as?: 'a' | 'button';
  isExternal?: boolean;
};

const ButtonBase: React.FC<ButtonBaseProps> = ({
  as,
  isExternal = false,
  children,
  color = ButtonColor.PRIMARY,
  variant = ButtonVariant.CONTAINED,
  size = ButtonSize.MD,
  paddingSize = ButtonPaddingSize.MD,
  className,
  disabled = false,
  isLoading = false,
  onClick,
  ...rest
}) => {
  const isLoadingEnabled = isLoading && !disabled;
  const classes = cn(
    'button',
    `${color}`,
    `${variant}`,
    `${size}`,
    `${paddingSize}`,
    { loading: isLoading },
    { disabled: disabled },
    className
  );
  const Component: React.ElementType =
    as || ('href' in rest ? (isExternal ? 'a' : Link) : 'button');

  return (
    <Component
      className={classes}
      onClick={
        onClick as
          | (React.MouseEventHandler<HTMLAnchorElement> &
              React.MouseEventHandler<HTMLButtonElement>)
          | undefined
      }
      disabled={Component === 'button' ? disabled || isLoadingEnabled : undefined}
      {...rest}
      {...(Component === Link && 'href' in rest && { to: rest.href })}>
      {isLoadingEnabled && <ClipLoader color="#ffff" size={16} />}
      {!isLoadingEnabled && <span>{children}</span>}
    </Component>
  );
};

export default ButtonBase;
