import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import './Navbar.css';
import { useAuthUserContext } from '../../providers/AuthUserProvider';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isAuthenticated, user, logout } = useAuthUserContext();
  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const closeDrawerAndLogout = () => {
    setDrawerOpen(false);
    void logout();
  };

  return (
    <nav className="navbar">
      <div className="sidebarTitle">
        <Link to={'/'}>estudi</Link>
      </div>
      <div className="navLinks">
        {!isAuthenticated ? (
          <Link className="navLink" to={'/auth/login'}>
            dar clases
          </Link>
        ) : (
          <Link className="navLink" to={`/teachers/${user?.id}/profile/edit`}>
            mi perfíl
          </Link>
        )}
        <Link className="navLink" to={'/help'}>
          ayuda
        </Link>
        {isAuthenticated && (
          <Link className="navLink" to={'/'} onClick={logout}>
            salir
          </Link>
        )}
      </div>
      <div className="drawer">
        <IconButton onClick={() => setDrawerOpen(true)}>
          <MenuIcon fontSize="large" sx={{ color: 'white', marginRight: '50%' }} />
        </IconButton>
        <Drawer
          anchor="top"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          sx={{
            '& .MuiPaper-root': {
              marginTop: '0px',
              paddingBottom: '10%',
              maxWidth: '100%',
              width: '100%',
              backgroundColor: 'white',
              background: '#184b76',
              color: 'white',
              alignItems: 'center',
              textAlign: 'center'
            }
          }}>
          <Fragment key="dropdown-mobile">
            <IconButton
              onClick={closeDrawer}
              sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
              <CloseIcon
                fontSize="large"
                sx={{
                  color: 'white',
                  marginRight: '4%',
                  marginTop: '8%'
                }}
              />
            </IconButton>
            {!isAuthenticated ? (
              <Link className="drawerLink" to={'/auth/login'} onClick={closeDrawer}>
                dar clases
              </Link>
            ) : (
              <Link className="drawerLink" to={`/teachers/${user?.id}/profile/edit`}>
                mi perfíl
              </Link>
            )}
            <Link className="drawerLink" to={'/help'} onClick={closeDrawer}>
              ayuda
            </Link>
            {isAuthenticated && (
              <Link className="drawerLink" to={'/'} onClick={closeDrawerAndLogout}>
                salir
              </Link>
            )}
          </Fragment>
        </Drawer>
      </div>
    </nav>
  );
};

export default Navbar;
